// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-collaboration-js": () => import("./../src/pages/collaboration.js" /* webpackChunkName: "component---src-pages-collaboration-js" */),
  "component---src-pages-collection-js": () => import("./../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-contactus-js": () => import("./../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-sketches-js": () => import("./../src/pages/sketches.js" /* webpackChunkName: "component---src-pages-sketches-js" */),
  "component---src-templates-sub-collection-template-js": () => import("./../src/templates/subCollectionTemplate.js" /* webpackChunkName: "component---src-templates-sub-collection-template-js" */)
}

